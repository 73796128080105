import {ReactComponent as Pig404} from "../../images/pig-404.svg";

export default function AccountPage(){
    return(
        
    <div style={{margin:"auto 15% auto 15%"}}>
        <div 
          
          className="content-container" 
          style={{
            borderRadius: "15px 15px 1rem 1rem"
          }}>

            <div className="content-container" style={{
              backgroundColor: "#2FC06B",
              color: "white",
              borderRadius: "15px 15px 0 0",
              padding: " 1rem 1rem",
              justifyContent: "center",
              alignContent: "center",
              boxShadow:"none"
            }}>
                <h1 style={{margin: "1rem"}}>My Account</h1> 
          </div>

          <div style={{
            display: "grid",
            justifyContent: "center"
          }}>
            <Pig404 style={{justifySelf: "center"}}/>
            <p style={{justifySelf: "center"}}> 👷‍♀️This area is currently under construction! 👷‍♂️
              <br/> Check back later! 👋</p>
          </div>
      </div>
    </div>
        
    )
}