import topics from "./Topic-Module/topic-content";
import Card from "../UI/Card";

export default function CoursePage(){
    return(
        <>
            <h1> Courses </h1> 
            <CourseListing/>
        </>
    )
}

function Course({course, key}){
    
    let locked = course.locked ? "locked" : "";

    const link = `/grow/topics/${course.topic_url}`;
    return(
        <Card 
            title ={course.topic_shorthand} 
            image={ course.image === null ? "": course.image } 
            topicColor={course.topic_color}
            link={link}
            classes={"row-item " + locked}
            key={key}
        />
    )
}

function CourseListing(){
    
    const fullCourse = [];
    for (let i = 0; i < topics.length; i+=3){
        let rowItems = []; // create the row

        //create items in the row
        let tempIndex = i;
        rowItems.push(<Course course = {topics[tempIndex]} key={topics[tempIndex].id}/>)
        tempIndex++;
        rowItems.push(<Course course = {topics[tempIndex]} key={topics[tempIndex].id}/>)
        tempIndex++;
        rowItems.push(<Course course = {topics[tempIndex]} key={topics[tempIndex].id}/>)
    
        //push the row with proper styling
        fullCourse.push(
            <div className="row" style ={{margin: "4rem"}}>
                {rowItems}
            </div>
        )
    }

    return (
        <div className="container d-grid gap-1">
            {fullCourse}
        </div>
    )
}
