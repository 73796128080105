import React from 'react';
import '../styles/App.css';
import {  Route, Switch } from 'react-router-dom';
import { AuthProvider } from "./User-Authentication/AuthContext";


//Route Files
import PrivateRoute from './Routes/PrivateRoute';
import ErrorRoute from './Routes/ErrorRoute';

//Normal Web Pages
import Home from "./Home";
import About from "./About";

//User Pages
import UserHomePage from "./User-Pages/UserHomePage";
import CoursePage from "./User-Pages/CoursePage";
import ProgressPage from "./User-Pages/MyProgressPage";

//Topic Module Pages
import LearningModule from "./User-Pages/Topic-Module/LearningModule";
import Lecture from "./User-Pages/Topic-Module/Lecture";

//Authentication pages
import Login from "./User-Authentication/Login/LoginPage.js";
import Signup from "./User-Authentication/Sign-up/Signup";
import forgetPassword from "./User-Authentication/Forget-Credentials/forgetPassword";

//UI Components
import NavBar from "./UI/NavBar";
import Footer from "./UI/Footer";

import FinanceGoalForm from './User-Pages/Topic-Module/GoalBox';
import AccountPage from './User-Pages/MyAccountPage';
import FinanceGoalPage from './User-Pages/MyFinanceGoals';
import SettingsPage from './User-Pages/SettingsPage';




function App() {
  

  return (
        <div className="app-flex">
          <div className="app-nav"> 
            <NavBar/>
          </div>
          <div className="app-content">
            <AuthProvider>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/about" exact component = {About}/>
              
              
              <Route path="/login/forget-password" exact component ={forgetPassword}/>
              <Route path='/login' exact component={Login}/>
              <Route path='/sign-up' exact={true} component={Signup}/>
              

              <PrivateRoute path="/grow" exact component = {UserHomePage}/>
              <PrivateRoute path="/grow/topics-list" exact component = {CoursePage}/>
              <PrivateRoute path="/grow/topics/:id"  exact component={LearningModule}/>
              <PrivateRoute path="/grow/topics/:topic_name/:lecture_name/:id" exact component= {Lecture}/>
              <PrivateRoute path="/grow/topics/:topic_name/projects/:lecture_name/:id" exact component={FinanceGoalForm}/>
              <PrivateRoute path="/grow/my-progress" exact component={ProgressPage}/>
              <PrivateRoute path="/grow/my-account" exact component={AccountPage}/>
              <PrivateRoute path="/grow/my-finance-goals" exact component={FinanceGoalPage}/>
              <PrivateRoute path="/grow/settings" exact component={SettingsPage}/>
              <Route component={ErrorRoute}/>

            </Switch>
            </AuthProvider>
          </div>
          
          {false && (<Footer/>)}
      </div>
  );
}

export default App;
