const topics = [
    
    {
        topic_shorthand: "Intro",
        topic_url: "intro",
        topic_color: "#9a2009",
        title_color: "white",
        locked: false,
        topic_title: "Introduction to Sprout",
        description: [`Welcome to the start of something new! Sprout is all about 
                    helping you on your personal finance journey. There's just a lot
                    of stuff to go through and can be confusing. That's why we made
                    this dreaded tutorial that helps you understand Sprout and how
                    to set up goals for your future!	
                    `],
        lectures: [
            {
                id: 1,
                lecture_name: "Starting to Sprout",
                color: "#008080",
                lecture_content: [
                    `<h2> Welcome to Sprout! </h2>
                    This project is an online learning website that is related 
                    to financial education for you who are in middle school to 
                    high school, so you can prepare for the financial 
                    transformation of adulthood (after 18).`,
                    `The website will provide you with courses related to 
                    finance including tax, interest rate analysis, student loans
                    , car loans, credit cards, etc., to help you learn finance 
                    efficiently. In addition, there are exams and quizzes after 
                    each chapter and lectures that help you check your learning 
                    progress.`
                ],
                lecture_quiz1: [
                    {
                        question: "SPROUT is an e-learning platform to teach farmers:",
                        options: [
                            "Of course!",
                            "Definitely!",
                            "False, SPROUT is to teach finance",
                            "Absolutely!"
                        ],
                        answer: 2
                    }
                ],
                next_topic: "Exploring Sprout"
            },

            {
                id: 2,
                lecture_name: "Exploring Sprout",
                color: "#ff0080",
                lecture_content: [
                    `<h2>Navigation Crash Course🗺️</h2> 
                    Sprout has a lot of features to navigate through and it can
                    be hard to know where to find what. Here's a little crash 
                    course in what Sprout offers to learners like you!`,
                    `<h3>A Quick Tour🧭</h3>`,

                    ` <strong>Home</strong>🏠: <br/>
                        Your one-stop-shop to find courses, check your profile, 
                        and see your progress.
                        `,
                    `<strong>Account</strong>👤: <br/> 
                        A place to edit/review your account information, such as
                        your password, email address, phone number, and soon to
                        be added badges to celebrate your acomplishments in 
                        Sprout. `,

                    `<strong>Courses</strong>📔: <br/>
                        Grow your financial knowledge with course that tackled 
                        different areas of personal finance. Read short lectures
                        and test your understanding with quick concept checks and
                        applying the learning to your own goals.`,

                    `<strong>My Progress</strong>📈: <br/> You can check your 
                        chapter learning progress here, the tree will grow up 
                        according to your chapter’s completion progress, the 
                        more you finish, the taller it grows.`,

                    `<strong>Finance Goals</strong>🥅: <br/> Check back to see all
                    the goals you wrote down in each lecture and track how well 
                    you are progressing to them. You can alway adjust your goals
                    to match the changes in your dreams.`
                ],
                
                next_topic: "Goal Setting"
            },

            {
                id: 3,
                lecture_name: "Goal Setting",
                color: "#a97272",
                lecture_content: [
                    `<h2>Dream Big, Dream SMART🤓</h2> 
                    We all have dreams and aspirations, but a lot of us don't 
                    know where to start. Usually we dream something out, work on
                    it, get lazy and then give up. If we don't want to be a 🤡, 
                    we need to set a plan. `,
                    `That's where goal setting comes in! If we want to achieve 
                    the life of our dreams, we need to create a plan that we can
                    use and stay consistent with. There are plenty of ways to 
                    measure your goals. One way is the SMART method.  SMART is a
                    powerful tool to stay on top of your grind to reach your 
                    goals`,

                    `<h3>SMART Breakdown⛏️</h3> `,
                    `<strong>Specific</strong>: Set your goals specific, 
                        detailed, and meaningful.<br/>`,
                    `<strong>Measurable</strong>: Make sure your goals can be 
                        tracked.<br/>`,
                    `<strong>Attainable</strong>: Ensure you can take action to 
                        achieve your goals.<br/>`,
                    `<strong>Realistic</strong>:  Keep your goal realistic and 
                        avoid unnecessary stress.<br/>`,
                    `<strong>Timely</strong>: Set real deadlines to help you to 
                        stay focused and motivated.<br/>`
                ],
                
                next_topic: "Creating Goals"
            }
        ],

        projects: [
            {
                id: 4,
                lecture_name: "Creating Goals",
                color: "#8CE3FF",
                lecture_content: [
                    `Understanding why we set goals is only important if we try them out!
                    Let's go ahead and create goals for three different times in our lives;
                    5 years from now, 20 years from now, and 50 years now. To hit our goals,
                    we have to make sure we create realistic goals and use methods like SMART to
                    make them possible. Go through the questions here and write up the goals you
                    want to hit at different times in your life.`,

                    `Where do you see yourself in 5 years? Your home, career, salary, lifestyle
                    are all important parts to list out. Write up to your hearts content!`,

                    `How about in 20 years? What would you like your life to be?`,

                    `In 50 years, where would do you see your life heading?`
                ],
                next_topic: "complete"
            }
        ]
    },

    {
        topic_shorthand: "Budget",
        topic_url: "budgeting",
        topic_color: "blue",
        title_color: "white",
        locked: true,
        topic_title: "Budgeting",
        description: [`Welcome to the start of something new! Sprout is all about 
                    helping you on your personal finance journey. There's just a lot
                    of stuff to go through and can be confusing. That's why we made
                    this dreaded tutorial that helps you understand Sprout and how
                    to set up goals for your future!	
                    `],
        lectures: [
            {
                id: 1,
                lecture_name: "Title 1",
                color: "#008080",
                lecture_content: [
                    " Yadda ",
                    "Yadda",
                    "Dadda?"
                ],
                lecture_quiz: [
                    {
                        question: "Example 1",
                        options: [
                            "a",
                            "b",
                            "c",
                            "d"
                        ],
                        answer: 3
                    }
                ]
            },

            {
                id: 2,
                lecture_name: "Title 2",
                color: "#ff0080",
                lecture_content: [
                    " Yadda ",
                    "Yadda",
                    "Dadda?"
                ]
            },

            {
                id: 3,
                lecture_name: "Title 3",
                color: "#a97272",
                lecture_content: [
                    " Yadda ",
                    "Yadda",
                    "Dadda?"
                ]
            }
        ]
    },

    {
        topic_shorthand: "Career",
        topic_url: "career",
        topic_color: "indigo",
        title_color: "white",
        locked: true,
        topic_title: "Career Planning",
        description: [`Welcome to the start of something new! Sprout is all about 
                    helping you on your personal finance journey. There's just a lot
                    of stuff to go through and can be confusing. That's why we made
                    this dreaded tutorial that helps you understand Sprout and how
                    to set up goals for your future!	
                    `],
        lectures: [
            {
                id: 1,
                lecture_name: "Title 1",
                color: "#008080",
                lecture_content: [
                    " Yadda ",
                    "Yadda",
                    "Dadda?"
                ],
                lecture_quiz: [
                    {
                        question: "Example 1",
                        options: [
                            "a",
                            "b",
                            "c",
                            "d"
                        ],
                        answer: 3
                    }
                ]
            },

            {
                id: 2,
                lecture_name: "Title 2",
                color: "#ff0080",
                lecture_content: [
                    " Yadda ",
                    "Yadda",
                    "Dadda?"
                ]
            },

            {
                id: 3,
                lecture_name: "Title 3",
                color: "#a97272",
                lecture_content: [
                    " Yadda ",
                    "Yadda",
                    "Dadda?"
                ]
            }
        ]
    },

    {
        topic_shorthand: "Investments",
        topic_url: "investing",
        topic_color: "red",
        title_color: "white",
        locked: true,
        topic_title: "Introduction to Sprout",
        description: [`Welcome to the start of something new! Sprout is all about 
                    helping you on your personal finance journey. There's just a lot
                    of stuff to go through and can be confusing. That's why we made
                    this dreaded tutorial that helps you understand Sprout and how
                    to set up goals for your future!	
                    `],
        lectures: [
            {
                id: 1,
                lecture_name: "Starting to Sprout",
                color: "#008080",
                lecture_content: [
                    " Yadda ",
                    "Yadda",
                    "Dadda?"
                ],
                lecture_quiz: [
                    {
                        question: "Example 1",
                        options: [
                            "a",
                            "b",
                            "c",
                            "d"
                        ],
                        answer: 3
                    }
                ]
            },

            {
                id: 2,
                lecture_name: "Exploring Sprout",
                color: "#ff0080",
                lecture_content: [
                    " Yadda ",
                    "Yadda",
                    "Dadda?"
                ]
            },

            {
                id: 3,
                lecture_name: "Goal Setting",
                color: "#a97272",
                lecture_content: [
                    " Yadda ",
                    "Yadda",
                    "Dadda?"
                ]
            }
        ]
    },

    {
        topic_shorthand: "Insurance",
        topic_url: "insurance",
        topic_color: "orange",
        title_color: "white",
        locked: true,
        topic_title: "Budgeting",
        description: [`Welcome to the start of something new! Sprout is all about 
                    helping you on your personal finance journey. There's just a lot
                    of stuff to go through and can be confusing. That's why we made
                    this dreaded tutorial that helps you understand Sprout and how
                    to set up goals for your future!	
                    `],
        lectures: [
            {
                id: 1,
                lecture_name: "Title 1",
                color: "#008080",
                lecture_content: [
                    " Yadda ",
                    "Yadda",
                    "Dadda?"
                ],
                lecture_quiz: [
                    {
                        question: "Example 1",
                        options: [
                            "a",
                            "b",
                            "c",
                            "d"
                        ],
                        answer: 3
                    }
                ]
            },

            {
                id: 2,
                lecture_name: "Title 2",
                color: "#ff0080",
                lecture_content: [
                    " Yadda ",
                    "Yadda",
                    "Dadda?"
                ]
            },

            {
                id: 3,
                lecture_name: "Title 3",
                color: "#a97272",
                lecture_content: [
                    " Yadda ",
                    "Yadda",
                    "Dadda?"
                ]
            }
        ]
    },

    {
        topic_shorthand: "Mortgages",
        topic_url: "mortgages",
        topic_color: "#c5bf6f",
        title_color: "white",
        locked: true,
        topic_title: "Career Planning",
        description: [`Welcome to the start of something new! Sprout is all about 
                    helping you on your personal finance journey. There's just a lot
                    of stuff to go through and can be confusing. That's why we made
                    this dreaded tutorial that helps you understand Sprout and how
                    to set up goals for your future!	
                    `],
        lectures: [
            {
                id: 1,
                lecture_name: "Title 1",
                color: "#008080",
                lecture_content: [
                    " Yadda ",
                    "Yadda",
                    "Dadda?"
                ],
                lecture_quiz: [
                    {
                        question: "Example 1",
                        options: [
                            "a",
                            "b",
                            "c",
                            "d"
                        ],
                        answer: 3
                    }
                ]
            },

            {
                id: 2,
                lecture_name: "Title 2",
                color: "#ff0080",
                lecture_content: [
                    " Yadda ",
                    "Yadda",
                    "Dadda?"
                ]
            },

            {
                id: 3,
                lecture_name: "Title 3",
                color: "#a97272",
                lecture_content: [
                    " Yadda ",
                    "Yadda",
                    "Dadda?"
                ]
            }
        ]
    }
]

export default topics;