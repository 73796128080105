import React, { useState } from "react";
import {  useHistory } from 'react-router-dom';
import topics from "./topic-content";
import Modal from "../../UI/Modal.js";
import {ReactComponent as Congrats} from "../../../images/congrats.svg";


export default function FinanceGoalForm({match}){
  const history = useHistory();
  let backLink = `/grow/topics/${match.params.topic_name}`
	let nextLink;
  let projectData;

  

  topics.every(topic =>{
		if(topic.topic_url === match.params.topic_name){
			
			topic.projects.every(project =>{
				if(project.lecture_name === match.params.lecture_name){
					//nextLink = `/grow/topics/${match.params.topic_name}/projects/${lecture.next_topic}/${index}`;
					projectData = project;
					return false;
				}
				return true;
			})
			
			return false;
		}
		return true;
	})

  return (
    <>
  
      <div div style={{margin:"auto 15% auto 15%"}}>
        <div 
          
          className="content-container" 
          style={{
            borderRadius: "15px 15px 1rem 1rem"
          }}>

            <div className="content-container" style={{
              backgroundColor: projectData.color,
              color: "white",
              borderRadius: "15px 15px 0 0",
              padding: " 1rem 1rem",
              justifyContent: "center",
              alignContent: "center",
              boxShadow:"none"
            }}>
                <h1 style={{margin: "1rem"}}>{match.params.lecture_name}</h1> 
          </div>
          
          <div className="mx-auto w-75 text-left">
            {/** First item is always the description */}
            <p style={{margin: "auto 2em"}}>{projectData.lecture_content[0]}</p>

            <QuestionMaker projectData={projectData} history={history} match={match} />    
          </div>
                

          <BottomPagination history={history} nextLink={nextLink} backLink={backLink}/>
        </div>
      </div>
    </>
  )
}


function QuestionMaker({projectData, history, match}){
  const [isModalOpen, setIsModalOpen] = useState(false);
  

  const handleSubmit = event => {
    event.preventDefault();

    setTimeout(() => {
    }, 3000);
  }

  

  const showModal = event=>{
    event.preventDefault();
    setIsModalOpen(true);
    
  }

  const hideModal = event=>{
    event.preventDefault();
    setIsModalOpen(false);
  }

  let tempArr=[]
  for(let i =1; i< projectData.lecture_content.length; i++){
    tempArr.push(
      <fieldset style={{margin: "2rem", display: "flex", flexDirection: "column"}}>
        <label>
          <p>{projectData.lecture_content[i]}</p>
        </label>
        <textarea type="text" className="height-150px" />
      </fieldset>
    );
  }

  return (
    <>
      <Modal show={isModalOpen} handleClose={hideModal}>
          <CongratulationsMessage 
            handleClose={hideModal} 
            history={history}
            match={match}  
          />
      </Modal>

      <form onSubmit={handleSubmit} style={{
        backgroundColor: "#F3F5F4",
        borderRadius: "5px"
      }}>
        {tempArr}
        <div style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          justifyItems: "end",
          margin: "auto 2em"
        }}>
          
          
          <div style={{
            gridColumn: "2 / 3"
          }}>
            <button className="cancelButton" >Clear</button>
            <button className="myButton" type="submit" onClick={showModal}>Save</button>
          </div>
          
        </div>

        
      </form>
    </>
  );
}

function CongratulationsMessage({handleClose, history, match}){
  return(
    <div style={{
      display: "grid",
      alignContent: "center",
      justifyContent: "center",
      margin: " 1em auto",
    }}>
      <h3 style={{
        alignSelf: "end", 
        justifySelf: "center",
        marginTop: "10px"}}>
          🥳Course Complete🥳
      </h3>

      <Congrats style={{
        maxHeight: "75%", 
        alignSelf: "end", 
        justifySelf: "center"}}/>

      <p style={{
        alignSelf: "center", 
        justifySelf: "center",
        margin: "10px"}}>
          Excellent job for finishing this course!
      </p>
      
      <button style={{
        alignSelf: "center", 
        justifySelf: "center",
        margin: "5px"
        }}
        className="myButton" 
        onClick={()=>history.push("/grow")}>Go Home
      </button>

      <a style={{
        alignSelf: "center", 
        justifySelf: "center",
        fontSize: "12px",
        margin: "5px",
        textDecoration: "none"
        }}
        onClick={handleClose} 
        href={match.params.url}
        className="about-us-link"
      >Return to Lecture
      </a>
    </div>
  )
}

function BottomPagination( {backLink, nextLink, history}){
  return(
    <div style={{
      display: "grid",
      gridTemplateRows: "1fr ",
      margin: "3em auto auto auto",
      
      justifyItems: "center",
      justifyContent: "space-between",
      alignContent: "center"

    }}>
      <a  style={{
          textAlign:"left",
          gridColumn: "1/2",
          margin: "2rem auto 2rem 2rem",
          textDecoration: "none"
        }}
        href={backLink}
        className="about-us-link"
      >
        <i className="fas fa-arrow-alt-circle-left"></i>
        Back to Topic 
      </a>
      
      <a  style={{
          textAlign:"right",
          marginRight: "2rem",
          marginTop: "0",
          gridColumn: "2/3",
          textDecoration: "none"
        }}
        href={nextLink}
        className="about-us-link"
      >
        
      </a>
    </div>
  )
}