
import React, { useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import 'firebase/firestore';
import defaultPhotoURL from "../../../images/profiles/default-profile-black-cat.svg";
import  firebase from "firebase/app";
import { Button, Card, Form, Container, Alert } from "react-bootstrap";

import "./signup.css";
import { useAuth } from '../AuthContext';
import { auth } from "../../FireBase";



function Signup(props) {
  
  const [error, setError] = useState('');
  const [ loading , setLoading ] = useState(false);

  //NEED: Function to add these values to a user
  const firstNameRef = useRef();
  const lastNameRef = useRef();

  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();

  const history = useHistory();
  const { signup } = useAuth();

  

  async function handleSubmit(event) {
    event.preventDefault();

    if(passwordRef.current.value !== passwordConfirmRef.current.value){
      return setError("Passwords do not match");
    }

    try{
      setError('')
      setLoading(true);
      await signup(emailRef.current.value, passwordRef.current.value)
      .then(cred =>{

        cred.user.updateProfile({
          displayName: `${firstNameRef.current.value} ${lastNameRef.current.value}`,
          photoURL: defaultPhotoURL
        })
        .catch(error=>{
          throw error;
        })
      });
      setLoading(false);
      await history.push("/grow")
    } 
    catch(err){
      setError(err);
    }
    setLoading(false);
  }


  return (
    <>
      <Container className ="d-flex align-items-center justify-content-center"
      style={{minHeight: "100vh"}}
      > 
        <Card>
          <Card.Body>
            <Form.Label>
            <img alt="sprout logo" src="/sprout_logo.jpg" style={{
                width: "10%",
                height: "10%"
              }}/>
            </Form.Label>
            
            <Form.Label>
              <h2>Create an account</h2>
            </Form.Label>

            {error && <Alert variant="danger">{error} </Alert>}
            <Form className="signup-form w-50" onSubmit = {handleSubmit}>
          
              <Form.Group controlId="formFirstName" class="m-2" >
                <Form.Control type="text" placeholder="First Name" 
                required ref={firstNameRef}/>
              </Form.Group>

              <Form.Group controlId="formLastName" class="m-2">
                <Form.Control type="text" placeholder="Last Name" 
                required ref={lastNameRef}/>
              </Form.Group>

              <Form.Group controlId="formEmail" class="m-2">
                <Form.Control type="email" placeholder="Enter email" 
                required ref={emailRef}/>
              </Form.Group>

              <Form.Group controlId="formPasswordPassword" class="m-2">
                <Form.Control type="password" placeholder="Password" 
                required ref={passwordRef}/>
              </Form.Group>

              <Form.Group controlId="formPasswordConfirm" class="m-2">
                <Form.Control type="password" placeholder="Confirm Password" 
                required ref={passwordConfirmRef}/>
              </Form.Group>

              <Button 
                disabled={loading}
                variant="success" 
                type="submit" 
                className="btn-lg btn-block"
                stlye = {{margin: "50px"}}
              >
                Register
              </Button>
              <br/>
              <Link to="/login/forget-password" > Forgot your password or account? </Link>
              <Link to="/login" style ={{margin: "50px"}}> Already have an account? </Link>
              <p className="mt-5 mb-3 text-muted">
                Copyright © 2021 FinTech. All Rights Reserved.
              </p>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </>
  )
}


function GoogleProviderLogin() {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  }

  return (
    <>
      <button className="sign-in" onClick={signInWithGoogle}>Sign up with Google</button>
    </>    
  )
}

export default Signup;