import React from "react";
import { useHistory } from "react-router-dom";
import Card from "../../UI/Card";
import topics from "../Topic-Module/topic-content";

function LearningModule({ match }){
	
	const history = useHistory();

	let topicData;
	
	topics.every(topic =>{
		if(topic.topic_url === match.params.id){
			topicData = topic;
			return false;
		}
		return true;
	})
	console.log(match)
    return(
        <div style={{margin:"auto 15% auto 15%"}}>
			{topicData && (
				<div className="content-container" style={{
					borderRadius: "2rem 2rem 1rem 1rem",
					paddingBottom: "10px"
				}}>  
					<div className="content-container" style={{
						backgroundColor: topicData.topic_color,
						color: topicData.title_color,
						borderRadius: "2rem 2rem 0 0",
						padding: " 1rem 1rem",
						justifyContent: "center",
						alignContent: "center",
						boxShadow:"none",
						margin: "none"
					}}>
	            		<h1 style={{margin: "1rem"}}> {topicData.topic_shorthand}</h1> 
					</div>

					<div style={{
						display: "flex",
						margin: "auto auto 2rem auto"
					}}> 
						<p  style={{
								textAlign:"left",
								margin: "0 auto 2rem 2rem",
								fontSize: "2em"
							}}
							onClick={() => {
								history.push("/grow/topics-list");
							}}>
							<i className="fas fa-arrow-alt-circle-left about-us-link"></i> 
							
						</p>

						<div className="mx-auto w-75 text-left">
							{ topicData.description.map((paragraph , index) =>(
								<p key={index} dangerouslySetInnerHTML={{__html: paragraph}}/>
							))}
						</div>
					</div>
					

					
				
					<LectureList title="Lectures" topicData={topicData}/>
					
					{/* Add section for projects and quizzes*/}
					<ProjectList title="Projects" topicData= {topicData}/>
				</div>)}
        </div>
    )
}


function LectureList({title, topicData}){
	return(
		<div>
			<h3 className="text-left" style={{marginLeft: "4rem"}}>{title}</h3>
					<div className=" gap-1" style ={{margin: "2rem auto 2rem auto "}}>
						<div className="row" style ={{
							margin: "2rem 3rem 3rem 3rem",
							alignContent: "center"
							}}>
							{topicData.lectures.map( (data ) => (
								LectureCard( data, topicData.topic_url)
							))}
						</div>
					</div>
		</div>
	)
}

function LectureCard (data, topic_name){
	const link = `/grow/topics/${topic_name}/${data.lecture_name}/${data.id}`;

	return (
		<Card
			key = {data.id}
			title ={data.lecture_name} 
            image={data.image} 
            topicColor={data.color}
            link={link}
            classes="row-item lecture-card"
		/>
	)
}

function ProjectList({title, topicData}){
	return(
		<div>
			<h3 className="text-left" style={{marginLeft: "4rem"}}>{title}</h3>
					<div className=" gap-1" style ={{margin: "2rem auto 2rem auto "}}>
						<div className="row" style ={{
							margin: "2rem 3rem 3rem 3rem",
							alignContent: "center"
							}}>
							{topicData.projects.map( (data ) => (
								ProductCard( data, topicData.topic_url)
							))}
						</div>
					</div>
		</div>
	)
}

function ProductCard(data, topic_name){
	const link = `/grow/topics/${topic_name}/projects/${data.lecture_name}/${data.id}`;

	return (
		<Card
			key = {data.id}
			title ={data.lecture_name} 
            image={data.image} 
            topicColor={data.color}
            link={link}
            classes="row-item lecture-card"
		/>
	)
}

export default  LearningModule;