import  firebase from "firebase/app";
import "firebase/auth";
require('dotenv').config()


const FireBaseConfig = firebase.initializeApp({
    apiKey: "AIzaSyB26tR3CxUdspMiZxKjcne8eQDmOdtnL8k",
    authDomain: "test-app-d9713.firebaseapp.com",
    projectId: "test-app-d9713",
    storageBucket: "test-app-d9713.appspot.com",
    messagingSenderId: "1037312103441",
    appId: "1:1037312103441:web:6bfa17993259b6a2900db0",
    measurementId: "G-F5YG4QPB6V"
  });

export const auth = FireBaseConfig.auth();
export default FireBaseConfig;