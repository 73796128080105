
import { Redirect } from 'react-router-dom';
import React from "react";
import "../styles/Animations.css";
import FireBaseConfig from "./FireBase";
import { useAuthState } from 'react-firebase-hooks/auth'; 
import {ReactComponent as Wave }from "../images/home-page-wave.svg";
import {ReactComponent as GrassOne} from "../images/grass-svg/g1.svg";
import {ReactComponent as GrassTwo} from "../images/grass-svg/g2.svg";
import {ReactComponent as Feature1} from "../images/feature_1.svg";
import {ReactComponent as Feature2} from "../images/feature_2.svg";
import {ReactComponent as Feature3} from "../images/feature_3.svg";

const auth = FireBaseConfig.auth();

function Home(props){
   let [user] = useAuthState(auth);

   return(
      <div style={{paddingBottom: "2rem"}}>
         {!user && (
            <div> 
            <IntroBox history = {props.history}/>
      
   
            <BusinessDescriptionBox />

            <FeaturesList/>

            <SignUpBox history={props.history}/>
         </div>
         )}

         {user && (<Redirect to="/grow"/>)}
      </div>

   ) 
}

/**
 * <h2> Grow your own Way</h2>
               <div className ="landing-page-grid-feature-1">
                  <h3> Topics to jumpstart your Finance Journey</h3>

               </div>

               <div className ="landing-page-grid-feature-2">
                  <h3> Short-and-Sweet Lessons</h3>
               </div>

               <div className ="landing-page-grid-feature-3">
                  <h3> Nurture your Path, your Way</h3>
               </div>
 */


function IntroBox({history}){
   return (
      <div className ="landing-page-grid-intro"
         style={{
            marginBottom: "0",
            height: "100%",
            padding: "2rem 0 0 0",
         }}> 
         <div className="home-page-intro-box"
         style={{
            padding: "2rem 0 4rem 0",
         }}
         >  
            <h2>Start Planting Your Future</h2>
            <h1 style={{
               fontSize: "90px",
               lineHeight: "105px",
               fontStyle: "normal",
               
               fontWeight: "bold"
            }}> Join Sprout </h1>
            <p style={{marginTop: "2rem"}}> 
               <strong>Free</strong> Online Financial Education. <br/>
                  <strong>Anytime.</strong> <strong>Anywhere.</strong> <strong>Anyone.</strong>
            </p>

            <button 
            style={{marginTop: "2rem"}}
            className = "home-get-started-btn"
            onClick = {(e)=> history.push("/about")}
            > Get Started
            </button>
         </div>

         
         <Wave/>
      </div>
   )
}


function BusinessDescriptionBox(){
   return (
      <div className ="landing-page-grid-info ">
         <div className="landing-page-grid-info-text"
         >
            <h2 className="feature-heading" 
               style={{fontSize:"55px"}}
            >Mission Statement</h2>
            <p className="feature-body">
               Here's some text that describes the overall product and relays
               the mission statement for the project. End with a message of the 
               purpose and the "dream for stronger financial literacy of tomorrow's
                  future".
            </p>
         </div>
      </div>
   )
}

function FeaturesList(){
   return (
      <div className ="container grid gap-4" style={{marginTop: "3rem"}} >
         <div className="row">
            <h2 style={{
               color:"#219653",
               fontWeight:"bold",
               fontSize:"50px",
               textAlign: "center"
            }}> Grow your own Way</h2>
         </div>

         <div className ="row" style={{marginTop: "3rem"}}>
            <div className="col">
               <Feature1/>
            </div>
            <div className="col text-left" style={{marginTop:"5% !important"}} > 
            <h3 className="feature-heading"> Personalized Education Experience</h3>
            <p className="feature-body">A personalized experience that tailors to your needs and learning
                financial literacy at your own pace and
                style, accelerating the learning curve.
            </p>
            </div>
         
         </div>

         <div className =" row" style={{marginTop: "3rem"}}>
            <div className="col text-left feature-vert-center" >
               <h3 className="feature-heading"
            >Learning Through Play</h3>
               <p className="feature-body">Gamified interactions and engaging short financial stories make 
                  your learning experience no longer a boring task. 
               </p>
            </div>
            <div className="col">
               <Feature2/>
            </div>
         </div>

         <div className ="row" style={{marginTop: "3rem"}}>
            <div className="col">
               <Feature3/>
            </div>
            <div className="col text-left feature-vert-center">
               <h3 className="feature-heading">Convenient Education</h3>
               <p className="feature-body">Utilizing your fragment times accessing Sprout
                  on any devices start learning, making your 
                  day more productive.
               </p>
            </div>
            
         </div>
      </div>
   )
}

function SignUpBox({history}){
   return (
      <div className="signup-box-container signup-box">
         <div className="signup-box-content">
            <h2 className="feature-heading" style={{
               color: "#636262",
               
            }}> Join Here</h2>

            <p className="feature-body"> Your journey to financial <br/> independence is a 
            click away.</p>
            
            <button 
            className = "home-sign-up-btn sign-up-animation"
            onClick = {(e)=> history.push('/sign-up')}> 
               Sign Up 
            </button>
         </div>
         <GrassOne className="grass-one"/>
         <GrassTwo className="grass-two"/>
      </div>
   )
}
export default Home;