import React from "react";
import FireBaseConfig from "../FireBase";
import Card from "../UI/Card";
import { useAuth } from '../User-Authentication/AuthContext';

import defaultPhotoURL from "../../images/profiles/default-profile-pig.svg";

const auth = FireBaseConfig.auth();


const UserHomePage = ()=> {
    
    const { currentUser } = useAuth();
    
    return (
        <div className="page-body">
        
        {currentUser && (
            <div style = {{
                margin: "auto 16% auto 16%", 
                paddingTop: "0.25rem", 
                backgroundColor: "#D8F4D8",
                borderRadius: "5rem 5rem 0 0"
                }} className="content-container " >
            
                <h1 style ={{
                    marginTop: "2rem"
                }}> {currentUser.displayName ? `Welcome, ${currentUser.displayName}` : "Welcome"}!</h1>
            
                {profilePic(currentUser)}
                <div className="container d-grid gap-2"  > 
                    <div className ="row" style ={{marginTop: "2rem"}}> 
                        <p className= "col"> 🏆 XP: {500}</p>
                        <p className= "col"> Course Completed: {0}</p>
                        <p className= "col"> 🔥 Streak: {0}</p>
                    </div>
                </div>
            </div>)}

        <div 
            className=" d-grid gap-1 content-container" 
            style = {{
                margin: " auto 16%",
                paddingTop: "0.25rem",
                borderRadius: "0 0 3rem 3rem"
            }}>
                <div className="row" style={{margin: "3%"}}> 
                    <Card 
                        title="Courses📚" 
                        link = {`/grow/topics-list`} 
                        classes="row-item test-color-1"
                    />

                    <Card 
                        title="My Progress📊" 
                        link = {`/grow/my-progress`} 
                        classes="row-item test-color-2"
                    />

                </div>

                <div className="row" style={{margin: "3%"}}> 
                    <Card 
                        title="My Finance Plan📋" 
                        link = {`/grow/my-finance-goals`} 
                        classes="row-item test-color-3"
                    />

                    <Card 
                        title="Account👤" 
                        link = {`/grow/my-account`} 
                        classes="row-item test-color-4"
                    />
                </div>
            </div>
        </div>
    )
 }

 function profilePic(user){
    try {
        if(user.photoURL === null){
            return  (
                <img 
                    src={defaultPhotoURL} 
                    className="size" 
                    alt="pig default profile pic"/>);
        }
        else {
            const {photoURL} = auth.currentUser;
            
            return (
                <img 
                    src={photoURL} 
                    className="size"
                    alt="profile pic"
                />);
        }
    }
    catch (err) {
        console.log(`error: ` + err);
    }
 }
 export default UserHomePage;