import {useHistory} from 'react-router-dom';

import Quiz from "./Quiz";
import topics from "./topic-content";

export default function Lecture({match}){
    const history = useHistory();
    
	let lectureData;
	let backLink = `/grow/topics/${match.params.topic_name}`
	let nextLink;
			
			
	console.log(match)
	topics.every(topic =>{
		if(topic.topic_url === match.params.topic_name){
			let index = match.params.id;
			index++;
			
			topic.lectures.every(lecture =>{
				if(lecture.lecture_name === match.params.lecture_name){
					nextLink = lecture["next_topic"].length === topic.projects[0]["lecture_name"].length ?
					`/grow/topics/${match.params.topic_name}/projects/${lecture.next_topic}/${index}`:
					`/grow/topics/${match.params.topic_name}/${lecture.next_topic}/${index}`;
					
					return false;
				}
				return true;
			})
			
			index-=2;
			lectureData = topic.lectures[index];
			return false;
		}
		return true;
	})
    
	const isLectureDataSet= lectureData === undefined? false : true;
	
    return(
        <div div style={{margin:"auto 15% auto 15%"}}>
			<div 
				true={isLectureDataSet} 
				className="content-container" 
				style={{
					borderRadius: "15px 15px 1rem 1rem"
				}}>

                <div className="content-container" style={{
					backgroundColor: lectureData.color,
					color: "white",
					borderRadius: "15px 15px 0 0",
					padding: " 1rem 1rem",
					justifyContent: "center",
					alignContent: "center",
					boxShadow:"none"
				}}>
	            	<h1 style={{margin: "1rem"}}> 
						{match.params.lecture_name} </h1> 
				</div>
                
                

                <div className="page-body w-75 mx-auto" style={{paddingTop: "0px"}}>
							{lectureData["lecture_content"].map((paragraph , index) =>(
								<p className="text-left mt-4 mb-3"key={index} dangerouslySetInnerHTML={{__html: paragraph}}/>
							))}
				</div>     

				<Quiz content={lectureData.lecture_quiz === undefined? null: lectureData.lecture_quiz}/>

                <BottomPagination history={history} nextLink={nextLink} backLink={backLink}/>
            </div>
        </div>
    )
}

function BottomPagination( {backLink, nextLink, history}){
	return(
		<div style={{
			display: "grid",
			gridTemplateRows: "1fr ",
			margin: "3em auto auto auto",
			justifyItems: "center",
			justifyContent: "space-between",
			alignContent: "center"

		}}>
			<a  style={{
					textAlign:"left",
					margin: "2rem auto 2rem 2rem",
					gridColumn: "1/2",
					textDecoration: "none"
				}}
				href={backLink}
				className="about-us-link"
			>
				<i className="fas fa-arrow-alt-circle-left"></i> Back to Topic 
			</a>
			
			<a  style={{
					textAlign:"right",
					margin: "2rem 2rem 2rem auto",
					gridColumn: "2/3",
					textDecoration: "none"
				}}
				href={nextLink}
				className="about-us-link"
			>
				Next Lecture <i className="fas fa-arrow-alt-circle-right" ></i>
			</a>
		</div>
	)
}
