import { Link } from 'react-router-dom'; 
import FireBaseConfig from "../FireBase";
import { useAuthState } from 'react-firebase-hooks/auth';
import { Redirect } from "react-router-dom";
import React, { useState, useEffect } from "react";


const auth = FireBaseConfig.auth();

function NavBar(props){
   let [user] = useAuthState(auth);
   const [isSignedIn, setIsSignedIn] = useState(user);

   useEffect(()=>{
      setIsSignedIn(user);
   }, [user])


   const login = async () => { return <Redirect to = "/login"/>}
   const logout = async () => {
      auth.signOut();
      setIsSignedIn(!user);
      return <Redirect to= "/"/>
   }

   
    return(
      <header>
         <div className="nav-bar-box">
         { !user && (horizontalBar({login , logout}))} 
         { user && (verticalBar({logout, setIsSignedIn , isSignedIn}))}
         </div>
       
       </header>
    )
 }

const verticalBar = ({ logout , setIsSignedIn, isSignedIn})=>{
   return (
      <nav className = "sidebar">
                <ul className = "sidebar-nav">
                    
                    <li className = "sidebar-item">
                        <a href="/grow" className="sidebar-link">
                        <i className="fas fa-home big-icon"></i>
                        <span className="link-text">Home</span>
                        </a>
                    </li>

                    <li className = "sidebar-item">
                        <Link to="/grow/my-account" className="sidebar-link">
                        <i className="far fa-user-circle big-icon"></i>
                        <span className="link-text">Account</span>
                        </Link>
                    </li>


                    <li className = "sidebar-item">
                        <Link to="/grow/topics-list" className="sidebar-link">
                        <i className="fas fa-book big-icon"></i>
                        <span className="link-text">Courses</span>
                        </Link>
                    </li>

                    <li className = "sidebar-item">
                        <Link to="/grow/my-progress" className="sidebar-link">
                        <i className="fas fa-chart-bar big-icon"></i>
                        <span className="link-text">My Progress</span>
                        </Link>
                    </li>

                    <li className = "sidebar-item">
                        <Link to="/grow/my-finance-goals" className="sidebar-link">
                        <i className="fas fa-piggy-bank big-icon"></i>
                        <span className="link-text">Finance Goals</span>
                        </Link>
                    </li>

                    <li className = "sidebar-item">
                        <a href="/grow/settings" className="sidebar-link">
                        <i className="fas fa-cog big-icon"></i>
                        <span className="link-text">Settings</span>
                        </a>
                    </li>

                    <li  onClick={ logout } className = "sidebar-item">
                        <div className="sidebar-link" style={{
                           cursor: "pointer"
                        }}>
                        <i className="fas fa-sign-out-alt big-icon"></i>
                        <span className="link-text">Sign Out</span>
                        </div>
                     </li>
                </ul>
            </nav>
   )
}

const horizontalBar = ({login , logout , setIsSignedIn, isSignedIn})=>{
   return (
      <nav className="nav-center">
         <ul className = "nav-ul">

            <Link to="/sign-up">
            <li className ="nav-li router-link">Sign Up</li>
            </Link>

            <Link to= "/login">
               {isSignedIn? 
               <li  onClick={ logout } style = {{float: ""}} 
                  className ="nav-li router-link">Logout</li>
               : <li onClick={ login }  style = {{float: ""}} 
                  className ="nav-li router-link">Log In</li>}
            </Link>

            <Link to="/about">
            <li className ="nav-li router-link">About</li>
            </Link>

            <Link to="/" >
            <li className ="nav-li router-link">Home</li>
            </Link>
         </ul>
      </nav>
   )
}

 export default NavBar;