import React from "react";
import { Button, Form, Card, Container } from "react-bootstrap";

import "./forgetPassword.css";

const forgetPassword = () => {
  return (
    <Container className ="d-flex align-items-center justify-content-center"
    style={{minHeight: "100vh"}}> 
      <Card>
        <Card.Body>
          <h2>Forget Password</h2>
          <Form className="forgetpassword-form">
      
            <Form.Group controlId="formEmail">
              <Form.Control type="email" placeholder="Enter email" />
              <Button variant="success" type="submit" className="btn-lg btn-block">
                Send email
              </Button>
            </Form.Group>

            <a href="/sign-up"> Never have an account? </a>
            <p className="mt-5 mb-3 text-muted">
              Copyright © 2021 FinTech. All Rights Reserved.
            </p>
          </Form>
        </Card.Body>
      </Card>
    </Container>
    
  );
};

export default forgetPassword;