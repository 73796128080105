import React from "react";
import "./quiz-styles.css"
export default function  Quiz({content}) {
    const questions = content;
	console.log(content)
	

	return (
		<> 
			{content !== null && (
				<div 
				className ="content-container w-75 mx-auto" 
				style={{
					boxShadow:"none",
					backgroundColor: "#D1D9CE",
					borderRadius: "15px",
				}}>
	
				{questions.map( (item, index)=>(
					<Question 
						key = {index} 
						questionData = {item}
						index={index}
					/>
				))}
				
				
				</div>
			)}
			{content === null && 
				<div 
				className ="content-container w-75 mx-auto" 
				style={{
					boxShadow:"none",
					backgroundColor: "#D1D9CE",
					borderRadius: "15px",
				}}> <p> Oops, we hit a snag! 😳
				<br/> A quiz has yet to be made for this section!
				</p></div>}
		</>
		
	);

	function Question( {questionData , index} ){
		let correctAnswer = questionData.options[questionData.answer];
		console.log(correctAnswer)
		index++;

		

		return (
			<> 
				<div className="question-section">
					<div> <h3>{index}</h3></div>
					<div className='question-text'>
						{questionData.question}
					</div>
				</div>

				<div className='answer-section' style={{
					margin: "auto 50px"
				}}>
					<form style={{
						display: "grid",
						justifyContent: "left",
						alignItems: "center"						
					}}
					className="">
					{questionData.options.map((answerOption, index) => (
							<div className="text-left m-3"> 
								<input 
									type="button"
									id={answerOption} 
									name= "option" 
									value={index+1}
									className="answer-button mr-3"
								/> 
								<label className="ml-5 answer-label"for={answerOption}>{answerOption}</label>
							</div>
						))}
					</form>
				</div>
			</>
		)
	}
}