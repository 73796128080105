
import React, { useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import "./Login.css";
import FireBaseConfig from "../../FireBase";
import 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import  firebase from "firebase/app";
import { Button, Form, Card, Container } from "react-bootstrap";
import { GoogleLoginButton } from "react-social-login-buttons";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAuth } from "../AuthContext";

const auth = FireBaseConfig.auth();

export default function Login(props) {

  const emailRef = useRef();
  const passwordRef = useRef();

  const [error, setError] = useState('');
  const [ loading , setLoading ] = useState(false);
  const { login } = useAuth();
  const history = useHistory();
  

  async function handleSubmit(event) {
    event.preventDefault();

    try{
      setError('')
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      history.push("/grow")
    } 
    catch{
      setError("Failed to create an account");
    }
    setLoading(false);
  }


  return (
    <Container className ="d-flex align-items-center justify-content-center"
    style={{minHeight: "100vh"}}> 
      <Card>
        <Card.Body>
          <h2>Please Log In</h2>
          <Form className="login-form" onSubmit = {handleSubmit}>

            <Form.Group controlId="formEmail">
              <Form.Label >Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" 
              required ref={emailRef}
              />
            </Form.Group>

            <Form.Group controlId="formPasswordPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" 
              required ref={passwordRef}
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="btn-lg btn-block">
              Log in
            </Button>
            <div className="text-center pt-3">
              Or continue with your social account
            </div>
            <GoogleProviderLogin history = {history}/>
            {/*<FacebookLoginButton className="mt-3 mb-3 text-center" />
            <GithubLoginButton className="mt-3 mb-3 text-center" />*/}
            <div className="text-center">
              <Link to="/sign-up">Sign up</Link>
              <span className="p-2">|</span>
              <Link to="/login/forget-password">Forgot Password</Link>
            </div>
            <p className="mt-5 mb-3 text-muted">
              The Sprout website is a student project. Website rights are up for
               debate.
            </p>
          </Form>

        </Card.Body>
      </Card>
    </Container>
  )
}


function GoogleProviderLogin({history}) {
  const provider = new firebase.auth.GoogleAuthProvider();
  
  const signInWithGoogle = async() => {
    await auth.signInWithPopup(provider);
    history.push("/grow");
  }

  return (
    <>
      <GoogleLoginButton className="mt-3 mb-3 text-center" onClick={signInWithGoogle}/> 
      
    </>
  )
}

