import {Link} from "react-router-dom";
import "./card-styles.css";

export default function Card({title, image, topicColor, link, classes}){
    if(image){
        
    }
    else if(topicColor){

    }

    return(
    <div className={"col topic-card row-item " + classes} style ={{margin: "1rem"}}>
        <div className = "topic-card-header " style ={{backgroundColor : topicColor}}></div>
        <Link to= {link} style = {{textDecoration: "none", color: "black"}}>
             
            <div className ="card-body">
                    <h2 className ="card-title">{title}</h2>
            </div>
        </Link>
    </div>
    )
}