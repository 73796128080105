

function Footer(){
   
    return (<div className ="footer-home"> 
        <h3 > © 2021 FinTech Inc.  {`\n`} All Right Reserved. </h3>
    
    </div>)
    
}


export default Footer;