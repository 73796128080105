import React from "react";
import { Route, Redirect } from "react-router-dom";
import FireBaseConfig from "../FireBase";
import { useAuthState } from 'react-firebase-hooks/auth';

const auth = FireBaseConfig.auth();


const PrivateRoute = ({ component: Component, ...rest }) =>{ 
    let [user] = useAuthState(auth);
    
    
    return (
    <Route {...rest} render={(props) => (
      user
        ? <Component {...props} />
        : <Redirect to='/' />
    )} />
  )
}

export default PrivateRoute;