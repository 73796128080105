import React from "react";
import {  Redirect } from 'react-router-dom';
import {ReactComponent as MainArtwork} from "../images/about-page-main-art.svg";
import {ReactComponent as GrassOne} from "../images/grass-svg/g1.svg";
import {ReactComponent as GrassTwo} from "../images/grass-svg/g2.svg";

import {ReactComponent as Panda} from "../images/profiles/default-profile-panda.svg";
import {ReactComponent as Pig} from "../images/profiles/default-profile-pig.svg";
import {ReactComponent as BrownBear} from "../images/profiles/default-profile-brown-bear.svg";
import {ReactComponent as PolarBear} from "../images/profiles/default-profile-polar-bear.svg";
import {ReactComponent as BlackCat} from "../images/profiles/default-profile-black-cat.svg";
import {ReactComponent as WhiteCat} from "../images/profiles/default-profile-white-cat.svg";
import {ReactComponent as OrangeCat} from "../images/profiles/default-profile-orange-cat.svg";

const About = ()=> {
       return (<>
            
            <div className =""style={{
                     justifyContent:"center",
                     alignItems:"center",
                     marginTop: "3rem"
                  }}>
               {/*<AnimatedArtwork />*/}

               <SproutDescription/> 
               
               <TeamStory/>
               
               <TeamList/>

               <SignUpBox/>
            </div>
          </>
       )
 }
 
 function AnimatedArtwork(){
    return(
      <div style={{
         position: "relative"
      }}>
         
         <MainArtwork style={{
               position: "relative",
               minWidth: "100%",
               transform: "scale(1.2, 1)"
            
            }}/>
                
            <div style={{
               position: "absolute",
               
               top: "25%",
               left: "10%",
               textAlign: "left",
               
            }}>
            <h2 
               style={{
                  fontFamily: "",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "64px",
                  lineHeight: "75px",
                  color: "#219653",
                  justifySelf:"center",
               }}
            > Learn Finance at its Root</h2>
            
            <p style={{marginTop: "2rem"}}> 
               <strong>Free</strong> Online Financial Education. <br/>
                  <strong>Anytime.</strong> <strong>Anywhere.</strong> <strong>Anyone.</strong>
            </p>
            <button 
            style={{marginTop: "1rem"}}
            className = "home-get-started-btn"
            onClick = {(e)=> <Redirect to="/sign-up"/>}
            > Get Started
            </button>
            
         </div>
      </div>
    )
 }

 function SproutDescription(){
    return (
      <div style={{
         justifyContent:"center",
         alignItems:"center",
         marginBottom: "8rem"
      }}> 
      <h2 className="about-heading"> 
         About Sprout</h2>  
  
      <p className="about-body"> Sprout is an e-learning platform  designed to guide the younger
         generation to financial independence. Our platform was created 
         for the sole goal to teach financial literacy in an engaging 
         and personalized way for students to easily transition into 
         adulthood. Through our curriculum, we hope to empower and 
         nurture financial knowledge for our future.
         </p>
   </div>
    )
 }


 function TeamStory(){
    return (
      <div style={{
         justifyContent:"center",
         alignItems:"center",
         marginBottom: "8rem"
         
      }}> 
         <div style={{
            justifyContent:"center",
            display:"grid",
            gridTemplateColumns: "2fr 1fr ",
            gridTemplateRows: "1fr",
            gridColumnGap:"1em",
            margin:"2rem 35% 1rem 35%"
         }}> 
            <h2 className="about-heading" 
            style={{
               display: "flex",
               gridColumnStart:" 1 / 2",
               justifySelf:"flex-end"
         }}> Our Story</h2> 

         <svg style={{
            gridColumnStart:" 2 / 3",
            justifySelf:"start"
         }}width="129" height="85%" viewBox="0 0 129 94" fill="none" xmlns="http://www.w3.org/2000/svg">
         <ellipse cx="64.4995" cy="38.4178" rx="64.4995" ry="38.4178" fill="#6FCF97"/>
         <path d="M5.63931 90.6433C8.88501 73.2321 44.0319 71.9113 34.5266 69.521C7.62873 58.717 13.0611 61.2694 13.0611 61.2694L10.9275 70.9691L5.63931 90.6433Z" fill="#6FCF97"/>
         <circle cx="31.7212" cy="38.7702" r="10.5737" fill="white"/>
         <circle cx="64.1469" cy="38.7702" r="10.5737" fill="white"/>
         <circle cx="97.2778" cy="38.7702" r="10.5737" fill="white"/>
         </svg>
         </div>

         <p className="about-body">
            This website was created as part of the University of Bothell's 
            Applied Computing Capstone. The product team was diversely composed
            of MIS, CSS, and Business students to make Sprout a reality. Our team
            envisioned this project as a way to solve financial literacy in a 
            fun way for younger audiences. We aspire to continue this idea
            beyond the classroom and provide students the means to take hold
            of their financial future.
            </p>
      </div>
    )
 }

 function TeamList(){
    return(
      <div style={{
         marginBottom: "5rem"
      }}>
         <div style={{
               display: "grid",
               alignItems: "center",
               margin: "2rem",
               gridTemplateColumns: "1fr auto 1fr",
               gridTemplateRows: "repeat(1, 1fr)"
            }}>
            
            <GrassTwo  style={{
               gridRowStart: "1 / 2",
               justifySelf:"end",
               marginBottom:"3em"
            }}/>
            <h2  className="about-heading"> Our Team</h2>

            <GrassOne style={{
               gridRowStart: "3 / 4",
               justifySelf:"start",
               marginBottom:"2em"
            }} />
         </div>

         <div className="container d-grid gap-1" style={{
            margin: "1rem 2rem 1rem inherit",
            alignItems: "center",
            justifyContent: "center"
         }}>
            <div className="row">
               <Member 
                  name="Victoria Cao" 
                  role="Project Manager" 
                  picture={<Panda style =
                     {{
                        width: "161.94px",
                        height: "114.09px",
                        alignSelf: "center", 
                     }}/>}
                  linkedIn="https://www.linkedin.com/in/jiayu-victoria-cao/"
               />

               <Member 
                  name="Anujin Batbataar" 
                  role="Business Team Lead" 
                  picture={<BrownBear style =
                     {{
                        width: "161.94px",
                        height: "114.09px",
                        alignSelf: "center", 
                     }}/>}
                  linkedIn="https://www.linkedin.com/in/anujinbatbaatar/"      
               />

               <Member 
                  name="Roewyn Umayam" 
                  role="Programmer" 
                  picture={<Pig style =
                     {{
                        width: "161.94px",
                        height: "114.09px",
                        alignSelf: "center", 
                     }}/>}
                  linkedIn="https://www.linkedin.com/in/roewyn-umayam/"
                  instagram="https://www.instagram.com/roewynaboat_tkd/"
                  portfolio="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
               />
            </div>
            
            <div className="row">
               <Member 
                  name="Zhaolan Luo" 
                  role="UX/UI Lead" 
                  picture={
                     <BlackCat style =
                     {{
                        width: "161.94px",
                        height: "114.09px",
                        alignSelf: "center", 
                     }}/>}
                  linkedIn="https://www.linkedin.com/in/zhaolanluo/"
               />

               <Member 
                  name="Leon Xu" 
                  role="Developer" 
                  picture={
                     <PolarBear style =
                     {{
                        width: "161.94px",
                        height: "114.09px",
                        alignSelf: "center", 
                     }}/>}
               />
            </div>

            <div className="row">
               <Member 
                  name="Restine Hernandez" 
                  role="Researcher" 
                  picture={
                     <OrangeCat style =
                     {{
                        width: "161.94px",
                        height: "114.09px",
                        alignSelf: "center", 
                     }}/>}
                  linkedIn="https://www.linkedin.com/in/restinehernandez/"
               />

               <Member 
                  name="Monica Bao" 
                  role="Content Writer" 
                  picture={
                     <WhiteCat style =
                     {{
                        width: "161.94px",
                        height: "114.09px",
                        alignSelf: "center", 
                     }}/>}
                  linkedIn= "https://www.linkedin.com/in/yufei-bao-977bb0178/"
               />
               
            </div>
         </div>
      </div>
    )
 }

 function Member({ name , role , picture, linkedIn , instagram , portfolio}){
   return (
      <div className="col" style={{
         alignItems: "center",
         justifyContent: "center",
         
         gridTemplateColumns: "repeat(1, 1fr)",
         gridTemplateRows: "repeat(1, 1fr)",
         margin: "auto 4rem 3rem 4rem"
      }}> 
         
         <div style={{
               display: "flex",
               width: "195px",
               height: "195px",
               justifyContent: "center",
               alignItems: "center",
               background: "#d4f7e7",
               borderRadius: "100%",
               gridColumnStart: "span 3",
               alignSelf: "center",
               margin: "auto"
            }}>
               
               {picture}
            
            </div>
            
            <div style={{
               gridColumnStart: "span 3"
            }}>
               <p 
                  style={{
                     color: "#219653",
                     fontWeight: "500"
                  }}
               >
                  {name} - <br/> {role}
               </p>

               <p 
                  style={{
                     fontSize: "24px",
                     
                  }}
               >
                  <a className="about-us-link" href={linkedIn}> <i class="fab fa-linkedin"></i></a> 
                  <a className="about-us-link" href={instagram}>
                     <i class="fab fa-instagram" style={{
                     margin: "auto 1em auto 1em"
                     }}></i> 
                  </a> 
                  <a className="about-us-link" href={portfolio}>
                     <i class="fas fa-user"></i>
                  </a>
               </p>
               
            </div>

            
      </div>
   )
 }


 function SignUpBox(){
   return (
      <div className="signup-box-container signup-box">
         <div className="signup-box-content">
            <h2 className="feature-heading" style={{
               color: "#636262",
               
            }}> Contact Us</h2>

            <p className="feature-body"> 
               Are you having any trouble? <br/>
               Looking to partner with us? <br/>
               Journalists and bloggers? <br/>
               please reach out to: <br/>

               <bold style={{color: "#636262", }}>growithsprout@gmail.com</bold>
            </p>
            
            
         </div>
         <GrassOne className="grass-one"/>
         <GrassTwo className="grass-two"/>
      </div>
   )
}

 export default About;