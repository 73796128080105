import { useHistory } from "react-router-dom";

export default function ErrorRoute(){
    const history = useHistory();


    return (
        <>
        <h1> Sorry, an error occured</h1>
        <p> Apologies for any inconvienience, it looks like
        you tried to access a page that doesn't exist. </p>

        <button onClick ={()=> history.push("/")} > Return back to home</button>
        </>
    )
}