
import {ReactComponent as TreeProgress }from "../../images/tree-progress.svg";
export default function ProgressPage(){

    const progressValue = 25;

    return(
        <div className="content-container"
            style = {{
                marginLeft: "20%",
                marginRight: "20%",

            }}
        >
            <div className="content-container" 
                style = {{
                    alignItems: "center", 
                    padding:"1rem",
                    backgroundColor: "white",
                    borderRadius: "15px 15px 1rem 1rem"
                }}>
            
                <div className="content-container" style={{
                    backgroundColor: "#2FC06B",
                    color: "white",
                    borderRadius: "15px 15px 0 0",
                    padding: " 1rem 1rem",
                    justifyContent: "center",
                    alignContent: "center",
                    boxShadow:"none"
                }}>
                <h1> My Progress </h1>
                </div>
                 

                <div className="container d-grid gap-2"  >
                <div className="row">
                    <TreeProgress className ="progress-img"/>
                </div>
                
                <div className="row">
                    <progress max="100" value={progressValue}
                    style={{
                        
                        borderRadius: "10px"
                    }}> </progress>
                </div>
                
                <div className="row" style={{margin: "1rem"}}>
                    <button className="col" style={{margin: "5px"}}> See courses </button>
                    <button className="col" style={{margin: "5px"}}> See courses</button>
                </div>

                </div>
            </div>



        </div>
    )
}
